import React from "react";
import "./NavBar.css";
import { useDispatch, useSelector } from "react-redux";
import { changeDarkMode } from "../../actions/dark";
import LogoInsoftline from "../../assets/images/logoinsoft.png";

import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";

const NavBar = () => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state.dark);

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light fixed-top nb-content  ${
        darkMode ? "mode-dark" : "mode-light"
      }`}
    >
      <div className="container">
        <Link className="navbar-brand p-0" to="/">
          <div className="">
            <img height="50" src={LogoInsoftline} alt="" />
          </div>
        </Link>
        <div className="navbar-toggler ms-auto">
          <div
            className="prf-nb-mode-dark px-1"
            onClick={() => dispatch(changeDarkMode())}
          >
            {darkMode ? (
              <i
                className="bi bi-brightness-high-fill ms-2"
                style={{ color: "#F6D50C" }}
              ></i>
            ) : (
              <i className="bi bi-moon-stars-fill ms-2"></i>
            )}
          </div>
        </div>
        <button
          className="navbar-toggler btn-menu"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="bi bi-list h1"></i>
        </button>
        <div className="collapse  navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 nb-item">
            <li className="nav-item fw-bold">
              <ScrollLink
                to="inicio"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                delay={50}
                className="nav-link nb-link"
                data-animation="center"
              >
                Inicio
              </ScrollLink>
            </li>
            <li className="nav-item fw-bold mx-md-2">
              <ScrollLink
                to="about"
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
                delay={50}
                className="nav-link nb-link"
                data-animation="center"
              >
                Quienes somos?
              </ScrollLink>
            </li>
            <li className="nav-item fw-bold">
              <ScrollLink
                to="solucion"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                delay={50}
                className="nav-link nb-link"
                data-animation="center"
              >
                Soluciones
              </ScrollLink>
            </li>
            <li className="nav-item fw-bold mx-md-2">
              <ScrollLink
                to="servicio"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                delay={50}
                className="nav-link nb-link"
                data-animation="center"
              >
                Servicios
              </ScrollLink>
            </li>
            <li className="nav-item fw-bold">
              <ScrollLink
                to="projects"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                delay={50}
                className="nav-link nb-link"
                data-animation="center"
              >
                Proyectos
              </ScrollLink>
            </li>
          </ul>

          <ScrollLink
            to="contacto"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
            delay={50}
            className="btn nb-btn-contact fw-bold text-dark ms-md-2"
          >
            Contáctanos
          </ScrollLink>

          <div className="my-3 my-md-0 ps-md-4">
            <div
              className="prf-nb-mode-dark px-1"
              onClick={() => dispatch(changeDarkMode())}
            >
              {darkMode ? (
                <>
                  <span>Modo claro </span>
                  <i
                    className="bi bi-brightness-high-fill ms-2"
                    style={{ color: "#F6D50C" }}
                  ></i>
                </>
              ) : (
                <>
                  <small>Modo Oscuro </small>
                  <i className="bi bi-moon-stars-fill ms-2"></i>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;

import { useState } from "react";
import { requestPost } from "../request/api";

const useSendMessage = () => {
  const dataDefault = {
    email: "",
    mensaje: "",
  };

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(dataDefault);
  const [enviado, setEnviado] = useState(false);
  // const [error, setError] = useState(null);

  const handleChange = (evento) => {
    const name = evento.target.name;

    let copyObj = { ...data };
    copyObj[name] = evento.target.value;
    setData(copyObj);
  };

  const enviarMensaje = async () => {
    setLoading(true);

    let formData = new FormData();
    formData.append("email", data.email);
    formData.append("mensaje", data.mensaje);

    try {
      let response = await requestPost("/index.php", formData, false);
      if (response.status === 200) {
        if (response.data.status) {
          setLoading(false);
          setData(dataDefault);
          setEnviado(true);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return { handleChange, data, enviarMensaje, loading, enviado };
};

export default useSendMessage;

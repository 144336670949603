import React from "react";
import "./Footer.css";
import logoImage from "../../assets/images/nlogo.png";

const Footer = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 text-center">
          <img src={logoImage} alt="" style={{ maxHeight: "200px" }} />
        </div>
        <div className="col-12 pb-4 text-center">
          <span>© {new Date().getFullYear()}</span>
          <strong className="text-secondary-insof"> Insoftline </strong>
          <span> Todos los derechos reservados</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;

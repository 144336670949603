import axios from "axios";

let options = {
  baseURL: `https://insoftline.com/mail`,
  timeout: 10000,
};

const apiInstance = axios.create(options);

apiInstance.interceptors.request.use((req) => {
  const token = localStorage.getItem("token");
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }
  return req;
});

export const requestGet = async (url) => await apiInstance.get(url);

export const requestGetFiles = async (url) =>
  await apiInstance.get(url, {
    responseType: "arraybuffer",
  });

export const requestPost = async (url, data, isJson = true) =>
  await apiInstance.post(url, data, {
    headers: {
      "Content-Type": isJson ? "application/json" : "multipart/form-data",
    },
  });

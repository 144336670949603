import React, { lazy, Suspense } from "react";
import About from "../components/About/About";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Hero from "../components/Hero/Hero";
import MapContainer from "../components/Map/MapContainer";
import NavBar from "../components/NavBar/NavBar";
import Projects from "../components/Projects/Projects";
// import Services from "../components/Services/Services";
import Solutions from "../components/Solutions/Solutions";

const Services = lazy(() => import("../components/Services/Services"));

const HomeScreen = () => {
  return (
    <>
      <Header />
      <NavBar />
      <Hero />
      <About />
      <Solutions />
      <Suspense fallback={<div>Loading...</div>}>
        <Services />
      </Suspense>
      <Projects />
      <Contact />
      <MapContainer />
      <Footer />

      <div className="whatsapp-content shadow-lg">
        <a
          className="text-white"
          target="_blank"
          rel="noopener noreferrer"
          href="https://api.whatsapp.com/send/?phone=59176653715&text&app_absent=0"
        >
          <i className="bi bi-whatsapp text-white"></i>
        </a>
      </div>
    </>
  );
};

export default HomeScreen;

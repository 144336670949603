import React from "react";
import "./Solutions.css";
import chipImg from "../../assets/images/chip.png";
import screenImg from "../../assets/images/pantalla-tactil.png";
import computerImg from "../../assets/images/computadora.png";
import { Element, Link as ScrollLink } from "react-scroll";

const Solutions = () => {
  return (
    <Element name="solucion" className="element solucion">
      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center py-4 solutions-title">
              <h2>Nuestras soluciones</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6  col-lg-3 ">
              <div className="card shadow  solution-card solution-card-ti">
                <div className="card-body p-5">
                  <div className="solution-img-content">
                    <img src={computerImg} alt="" />
                  </div>
                  <div className="solution-tex-content mt-4">
                    <h5>TI Servicios</h5>
                    <p className="">
                      Resolvemos tareas de TI, que permitan a nuestros clientes
                      una mejor eficiencia laboral y empresarial con soluciones
                      tecnológicas.
                    </p>
                  </div>
                  <div className="d-flex solutions-btn-content">
                    <div
                      className="d-flex align-items-center p-2 solutions-btn"
                      data-animation="center"
                    >
                      <ScrollLink
                        to="contacto"
                        spy={true}
                        smooth={true}
                        offset={-150}
                        duration={500}
                        delay={50}
                        className=""
                      >
                        Descubre un poco más
                      </ScrollLink>

                      <i className="bi bi-chevron-right ms-2"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6  col-lg-3  ">
              <div className="card shadow  solution-card solution-card-li">
                <div className="card-body p-5">
                  <div className="solution-img-content">
                    <img src={screenImg} alt="" />
                  </div>
                  <div className="solution-tex-content mt-4">
                    <h5>LICENCIAS E IMPLEMENTACIÓN SISTEMA Q-FLOW</h5>
                    <p className="">
                      Herramienta de Gestión de Procesos de Negocio (BPM),
                      procesos que utilizan las empresas, implicita o
                      explicitamente, para llevar a cabo sus actividades.
                    </p>
                  </div>
                  <div className="d-flex solutions-btn-content">
                    <div
                      className="d-flex align-items-center p-2 solutions-btn"
                      data-animation="center"
                    >
                      <ScrollLink
                        to="contacto"
                        spy={true}
                        smooth={true}
                        offset={-150}
                        duration={500}
                        delay={50}
                        className=""
                      >
                        Me interesa
                      </ScrollLink>

                      <i className="bi bi-chevron-right ms-2"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6  col-lg-3 ">
              <div className="card shadow solution-card solution-card-mark">
                <div className="card-body p-5">
                  <div className="solution-img-content ">
                    <img src={chipImg} alt="" />
                  </div>
                  <div className="solution-tex-content mt-4">
                    <h5>SOLUCIONES DE NEGOCIO (MARKETING)</h5>
                    <p className="">
                      Trabajamos con una estrategia de marketing digital y
                      neuromarketing que abarca de manera global los recursos y
                      servicios de su empresa.
                    </p>
                  </div>
                  <div className="d-flex solutions-btn-content">
                    <div
                      className="d-flex align-items-center p-2 solutions-btn"
                      data-animation="center"
                    >
                      <ScrollLink
                        to="contacto"
                        spy={true}
                        smooth={true}
                        offset={-150}
                        duration={500}
                        delay={50}
                        className=""
                      >
                        Me interesa
                      </ScrollLink>

                      <i className="bi bi-chevron-right ms-2"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Solutions;

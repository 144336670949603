import React from "react";
import { Element } from "react-scroll";
import useSendMessage from "../../hooks/useSendMessage";
import "./Contact.css";

const Contact = () => {
  const { handleChange, data, enviarMensaje, loading, enviado } =
    useSendMessage();

  const handleSubmitMensaje = (e) => {
    e.preventDefault();
    enviarMensaje();
  };

  return (
    <Element name="contacto" className="element contacto">
      <div className="container-fluid my-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center contact-title">
              <h2>Contáctanos</h2>
            </div>
            <div className="col-12 col-md-8 col-lg-6 mt-4">
              <form onSubmit={(e) => handleSubmitMensaje(e)}>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Ingresa tu correo
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    required
                    value={data.email}
                    name="email"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label"
                  >
                    Escribe tu mensaje
                  </label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    name="mensaje"
                    value={data.mensaje}
                    onChange={(e) => handleChange(e)}
                  ></textarea>
                </div>
                {enviado && (
                  <div className="">
                    <div
                      className="alert alert-success alert-dismissible fade show"
                      role="alert"
                    >
                      <strong>Tu mensaje fue enviado!</strong> Gracias por
                      escribirnos.
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                    </div>
                  </div>
                )}

                <div className="text-center mt-4">
                  {loading ? (
                    <button
                      type="button"
                      className="btn btn-primary-insof px-5"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm me-3"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Enviando mensaje
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary-insof px-5"
                    >
                      Enviar mensaje
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Contact;

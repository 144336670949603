import { types } from '../types/types';

export const changeDarkMode = () => {
  return async (dispatch) => {
    let darkMode = JSON.parse(localStorage.getItem('dark'));
    if (darkMode === null) {
      darkMode = true;
      localStorage.setItem('dark', JSON.stringify(darkMode));
      dispatch(activeDarkMode(darkMode));
    } else {
      localStorage.setItem('dark', JSON.stringify(!darkMode));
      dispatch(activeDarkMode(!darkMode));
    }
  };
};

export const loadDarkMode = () => {
  return async (dispatch) => {
    let darkMode = JSON.parse(localStorage.getItem('dark'));
    if (darkMode === null) {
      darkMode = false;
    }

    dispatch(activeDarkMode(darkMode));
  };
};

export const activeDarkMode = (dark) => ({
  type: types.darkMode,
  payload: dark,
});

import React from "react";
import "./Hero.css";
import iMac from "../../assets/images/imac.png";
import iPad from "../../assets/images/ipad.png";
import iPhone from "../../assets/images/iphone.png";
import macBook from "../../assets/images/macbook.png";

const Hero = () => {
  return (
    <div className="container-fluid hero-container p-0">
      <div
        id="carouselExampleFade"
        className="carousel slide carousel-fade hero-carousel-container"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner hero-carousel-container">
          <div className="carousel-item active hero-carousel-container ">
            <div className="d-flex flex-column-reverse flex-column flex-md-row align-items-center justify-content-center hero-carousel-container ">
              <div className="hero-carousel-img-content animate__animated animate__backInLeft">
                <img src={iMac} alt="" className="hero-img-imac move-down" />
              </div>
              <div className="hero-carousel-text-content">
                <h2 className="hero-text-sda animate__animated animate__backInRight">
                  SOFTWARE
                  <br />
                  DISEÑO
                  <br />
                  APLICACIONES
                </h2>
              </div>
            </div>
          </div>

          <div className="carousel-item  hero-carousel-container">
            <div className="d-flex flex-column-reverse flex-column flex-md-row align-items-center justify-content-center hero-carousel-container">
              <div className="hero-carousel-img-content animate__animated animate__backInLeft">
                <img src={macBook} alt="" className="hero-img-imac move-down" />
              </div>
              <div className="hero-carousel-text-content">
                <h2 className="hero-text-sda animate__animated animate__backInRight">
                  DISEÑOS
                  <br />
                  CORPORATIVOS
                </h2>
              </div>
            </div>
          </div>

          <div className="carousel-item  hero-carousel-container">
            <div className="d-flex flex-column-reverse flex-column flex-md-row align-items-center justify-content-center hero-carousel-container">
              <div className="hero-carousel-img-content  d-flex flex-column animate__animated animate__backInLeft">
                <div className="text-center">
                  <img src={macBook} alt="" className="hero-img-macbook" />
                </div>
                <div className="d-flex justify-content-center">
                  <img src={iPhone} alt="" className="hero-img-iphone" />
                  <img src={iPad} alt="" className="hero-img-ipad" />
                </div>
              </div>
              <div className="hero-carousel-text-content">
                <h2 className="hero-text-sda animate__animated animate__backInRight">
                  AJUSTADOS
                  <br />A PANTALLAS
                </h2>
              </div>
            </div>
          </div>

          {/* <div className="carousel-item">
            <h1>DISEÑOS CORPORATIVOS</h1>
          </div>
          <div className="carousel-item">
            <h1>AJUSTADOS A PANTALLAS</h1>
          </div> */}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Hero;

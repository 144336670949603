import React from 'react';
import { Element } from 'react-scroll';
import './Header.css';

const Header = () => {
  return (
    <Element name="inicio" className="element inicio">
      <div className="header container-fluid fixed-top bg-secondary-insof">
        <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between py-2">
          <div className="header-phone-numbers">
            <i className="bi bi-telephone text-white me-2"></i>
            <a className="text-white" href="tel:+591766-53715">
              +591 766-53715
            </a>
            {/* <span className="mx-3 text-white">|</span>
            <a className="text-white" href="tel:+591766-53715">
              +591 755-27755
            </a> */}
          </div>
          <div className="text-white">
            <a
              className="text-white"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/insoftline"
            >
              <i className="bi bi-facebook text-white"></i>
            </a>
            <a
              className="text-white ms-3"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/insoftline"
            >
              <i className="bi bi-linkedin text-white"></i>
            </a>
            <a
              className="text-white mx-3"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/insoftlinebolivia/"
            >
              <i className="bi bi-instagram text-white"></i>
            </a>
            <a
              className="text-white"
              target="_blank"
              rel="noopener noreferrer"
              href="https://api.whatsapp.com/send/?phone=59176653715&text&app_absent=0"
            >
              <i className="bi bi-whatsapp text-white"></i>
            </a>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Header;

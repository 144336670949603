import React from "react";
import "./Projects.css";
import redmujer from "../../assets/images/redmujer.png";
import burton from "../../assets/images/burton.png";
import estiloapp from "../../assets/images/estiloapp.png";
import agropartners from "../../assets/images/agropartners.png";
import todoterreno from "../../assets/images/todoterreno.png";
import mariaaviles from "../../assets/images/mariaaviles.png";
import colosa from "../../assets/images/colosa.png";
import landicorp from "../../assets/images/landicorp.png";
import fexpolaboral from "../../assets/images/fexpolaboral.png";

import { Element } from "react-scroll";

const projects = [
  {
    id: 1,
    project: "Red mujer",
    url: "https://play.google.com/store/apps/details?id=com.baneco.redmujer&hl=es",
    image: redmujer,
  },
  {
    id: 2,
    project: "Burton Experss",
    url: "https://play.google.com/store/apps/details?id=com.burton.cliente",
    image: burton,
  },
  {
    id: 3,
    project: "Estilo App",
    url: "https://play.google.com/store/apps/details?id=com.estiloappinc.estiloapp",
    image: estiloapp,
  },
  {
    id: 4,
    project: "Agropartners",
    url: "http://www.agropartners.com.bo/",
    image: agropartners,
  },
  {
    id: 5,
    project: "Todo Terreno",
    url: "https://www.todoterrenonline.com/",
    image: todoterreno,
  },
  {
    id: 6,
    project: "Maria Aviles",
    url: "http://www.mariaaviles.co.uk/es/about/",
    image: mariaaviles,
  },
  {
    id: 7,
    project: "Colosa",
    url: "https://colosa.com.bo/",
    image: colosa,
  },
  {
    id: 8,
    project: "Landicorp",
    url: "https://www.todoterrenonline.com/",
    image: landicorp,
  },
  {
    id: 9,
    project: "Fexpolaboral",
    url: "https://www.fexpolaboral.com/",
    image: fexpolaboral,
  },
];
const Projects = () => {
  return (
    <Element name="projects" className="element projects">
      <div className="container-fluid pt-5">
        <div className="container mt-4">
          <div className="row">
            <div className="col-12 text-center py-4 services-title">
              <h2>
                Algunos de nuestros Clientes & <br /> Proyectos
              </h2>
            </div>
          </div>
          <div className="row justify-content-center">
            {projects.map((project, index) => (
              <div key={index} className="col-12 col-md-4 col-lg-3 mt-4">
                <div className="card p-0 m-0 shadow">
                  <div className="card-body p-0 m-0">
                    <div className="project-box">
                      <div className="project-img">
                        <img src={project.image} alt="" />
                        <div className="project-title"></div>
                      </div>
                      <div className="project-text">
                        <h4 className="fw-bold">{project.project}</h4>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={project.url}
                          className="btn project-btn text-dark mt-2"
                        >
                          Ver Proyecto
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <div key={index} className="col-12 col-md-4 col-lg-3 mt-4">
              <div className="project-card-container">
                <div className="project-card">
                  <div className="imgBx">
                    <img src={project.image} alt="" />
                    <div className="px-3">
                      <h2 className="">
                        Insoftline empresa minera dsdsdsdssds ssd sdsds sds{" "}
                      </h2>
                    </div>
                  </div>
                  <div className="project-card-content">
                    <a href="" className="btn btn-primary">
                      Visitar Pagina
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
      </div>
    </Element>
  );
};

export default Projects;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { loadDarkMode } from "../actions/dark";
import HomeScreen from "../pages/HomeScreen";

const AppRoutes = () => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state.dark);

  useEffect(() => {
    dispatch(loadDarkMode());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <div className={darkMode ? "mode-dark" : "mode-light"}>
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Redirect to="/" />
        </Switch>
      </div>
    </Router>
  );
};

export default AppRoutes;

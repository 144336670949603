/* eslint-disable no-unused-vars */
import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import React, { useState } from "react";
import { apiKeyMap } from "../../request/apiKeyMap";

import Logo from "../../assets/images/cropped-loog-pperfil-192x192.png";

const MapContainer = (props) => {
  const initialCenter = {
    lat: -17.79035,
    lng: -63.18085,
  };

  //   const { position, setPosition } = props;
  const initialZom = 15;

  // const [mapCenter, setMapCenter] = useState(initialCenter);
  const [zoom, setZoom] = useState(initialZom);
  const [activeMarker, setActiveMarker] = useState(initialCenter);
  const [showingInfoWindow, setShowingInfoWindow] = useState(true);

  const onMarkerClick = (props, marker, e) => {
    setShowingInfoWindow(true);
    setActiveMarker(marker);
  };

  const onMapClicked = (props, map, coord) => {
    // const { latLng } = coord;
    // const pos = { latitud: latLng.lat() + "", longitud: latLng.lng() + "" };
    // setPosition(pos);
    // setMapCenter(pos);
  };

  return (
    <div
      className="row justify-content-center position-relative"
      style={{ width: "100%", height: "450px" }}
    >
      <div className="col-12">
        <Map
          google={props.google}
          initialCenter={{
            lat: initialCenter.lat,
            lng: initialCenter.lng,
          }}
          // center={{
          //     lat: mapCenter.lat,
          //     lng: mapCenter.lng,
          // }}
          zoom={zoom}
          //   onClick={onMapClicked}
        >
          <Marker
            title={"Insofline"}
            name={"Insofline"}
            position={{
              lat: initialCenter.lat,
              lng: initialCenter.lng,
            }}
            onClick={onMarkerClick}
          />
          <InfoWindow marker={activeMarker} visible={true}>
            <div className="d-flex align-items-center">
              <img src={Logo} alt="logo" style={{ width: "60px" }} />
              <div className="ms-2">
                <h6 className="p-0 m-0">Insoftline</h6>
                <p>
                  Rene Moreno #625,
                  <br /> Santa Cruz de la Sierra
                </p>
              </div>
            </div>
          </InfoWindow>
        </Map>
      </div>
    </div>
  );
};

// export default GoogleMaps

export default GoogleApiWrapper({
  apiKey: apiKeyMap,
})(MapContainer);

import React from "react";
import "./About.css";

import aboutImage from "../../assets/images/desarrollo.gif";
import { Element } from "react-scroll";

const About = (props) => {
  return (
    <Element name="about" className="element about">
      <div className="container-fluid">
        <div className="container mt-5 pt-5">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="about-btn-text">
                <span className="text-white">INSOFTLINE</span>
              </div>
              <div className="mt-3 about-title">
                <h2 className="fw-bold">
                  Desarrollo de Software y Aplicaciones Móviles
                </h2>
              </div>
              <div className="mt-4">
                <h3 className="fw-bold">¿Quienes somos?</h3>
                <div className="d-flex mt-3 ps-4">
                  <i className="bi bi-circle-square h3 text-primary-insof"></i>
                  <p className="ps-3">
                    Insoftline es una empresa que busca dar solución de manera
                    accesible, confiable, rentable y garantizado.
                  </p>
                </div>
                <div className="d-flex ps-4">
                  <i className="bi bi-circle-square h3 text-primary-insof"></i>
                  <p className="ps-3">
                    Contando con integrantes especializados en el desarrollo de
                    software y aplicaciones.
                  </p>
                </div>
              </div>
              <div className="">
                <h3 className="fw-bold">¿Lo que buscamos?</h3>

                <div className="d-flex mt-3 ps-4">
                  <i className="bi bi-circle-square h3 text-primary-insof"></i>
                  <p className="ps-3">
                    Aportar soluciones a distintas problemáticas empresariales.
                  </p>
                </div>
                <div className="d-flex ps-4">
                  <i className="bi bi-circle-square h3 text-primary-insof"></i>
                  <p className="ps-3">
                    Optimizar el rendimiento de tu empresa de manera segura y
                    eficiente, utilizando tecnologías de la información, que son
                    la clave para funcionar en el mundo actual.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-md-5 d-flex align-items-center">
              <div className="about-image-container">
                <img src={aboutImage} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default About;

import { types } from '../types/types';

const intialState = {
  darkMode: false,
};

export const darkReducer = (state = intialState, action) => {
  switch (action.type) {
    case types.darkMode:
      return {
        ...state,
        darkMode: action.payload,
      };
    default:
      return state;
  }
};
